<template>
  <div class="smonitor">
    <div class = "filterBar flexboxb">
      <div class = "flexboxs">
        <div class = "filterItem" style="width: 200px;">
          <Cascader
            :value="farmScene"
            :data="farmOptions" 
            :load-data="getChildren" 
            trigger="click"  
            style="min-width: 100px;"
            placeholder="选择基地/场景"
            placement="top"
            :clearable="false"
            @on-change="farmSceneChange"
          />
        </div>
        <div class = "filterItem">
          <Cascader
            :data="sensorTypeOptions" 
            :load-data="getSensorCategoryList" 
            change-on-select
            placeholder="选择场景类型/监测类别"
            @on-change="(val, selData) => {filterChange('sensor', 'cas', val, selData)}"
          />
        </div>
        <div class = "filterItem">
          <Input placeholder="输入监测点名称" @on-change="e => {this.filterChange('groupName', 'input', e)}"/>
        </div>
        <div class = "filterItem">
          <Input placeholder="输入监测点名称" @on-change="e => {this.filterChange('name', 'input', e)}"/>
        </div>
      </div>
      <div v-if="accessData.sceneSensorAdd">
        <Button type = "primary" @click="monitorNew">添加监测</Button>
      </div>
    </div>
    <div class="monitorGrid">
      <Table border :columns="listCol" :data="listData" :loading="listLoading"></Table>
    </div>
    <div class="pageBar">
      <Page :total="page.total" :current="page.pageNo" :page-size="page.pageSize" show-elevator show-total @on-change ="pageChange"></Page>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Smonitor',
  props: {
    farmId: {
      type: Number,
      default: 0
    },
    sceneId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      listCol: [
        {
          title: '监测点名称',
          key: 'groupName',
          minWidth: 110,
          render: (m, {row}) => {
            let c = this.$createElement;
            if (!row.isE) return c('span', row.groupName);
            return c('Input', {
              'class': {
                noPass: row.groupName ? false : true,
              },
              props: {
                value: row.groupName,
              },
              ref: 'groupNameInput',
              on: {
                'on-change': e => {
                  if (!row.id) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      groupName: e.target.value
                    });
                  } else {
                    for (var i = 0; i < this.listData.length; i++) {
                      this.listData[i].id === row.id && this.listData.splice(i, 1, {
                        ...this.listData[i],
                        groupName: e.target.value
                      })
                    }
                  }
                  this.$nextTick(() => {
                    this.$refs.groupNameInput.focus();
                  })
                }
              }
            })
          }
        },
        {
          title: '监测名称',
          key: 'name',
          minWidth: 110,
          render: (m, {row}) => {
            let c = this.$createElement;
            if (!row.isE) return c('span', row.name);
            return c('Input', {
              'class': {
                noPass: row.name ? false : true,
              },
              props: {
                value: row.name,
              },
              ref: 'smonitornameInput',
              on: {
                'on-change': e => {
                  if (!row.id) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      name: e.target.value
                    });
                  } else {
                    for (var i = 0; i < this.listData.length; i++) {
                      this.listData[i].id === row.id && this.listData.splice(i, 1, {
                        ...this.listData[i],
                        name: e.target.value
                      })
                    }
                  }
                  this.$nextTick(() => {
                    this.$refs.smonitornameInput.focus();
                  })
                }
              }
            })
          }
        },
        {
          title: '场景类型/监测类别',
          key: 'sensorTypeName',
          minWidth: 140,
          render: (c, {row}) => {
            if (!row.isE) return c('span', row.sensorTypeName + ' / ' + row.sensorCategoryName);
            row.id && (row.sensorTypeArr = [+row.sensorType, +row.sensorCategory]);
            return c('Cascader', {
              props: {
                labelInValue: true,
                value: row.sensorTypeArr,
                data: this.sensorTypeOptions,
                loadData: this.getSensorCategoryList,
              },
              'class': {
                noPass: row.sensorTypeArr && row.sensorTypeArr.length > 1 ? false : true,
              },
              ref: 'sensorTypeCas',
              on: {
                'on-change': (val, selData) => {
                  if (!row.id) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      sensorTypeArr: val,
                      sensorType: val[0],
                      sensorCategory: val[1]
                    })
                  } else {
                    for (let i = 0; i < this.listData.length; i++) {
                      this.listData[i].id === row.id && this.listData.splice(i, 1, {
                        ...this.listData[i],
                        sensorTypeArr: val,
                        sensorType: val[0],
                        sensorCategory: val[1]
                      })
                    }
                  }
                }
              }
            })
          }
        },
        {
          title: '罗拉设备',
          key: 'unicomName',
          minWidth: 110,
          render: (c, {row}) => {
            if (!row.isE) return c('span', row.unicomName);
            return c('Select', {
              attrs: {
                // value: this.newData.farmId,
              },
              props: {
                labelInValue: true,
                value: row.unicomGuid,
              },
              'class': {
                noPass: row.unicomGuid ? false : true,
              },
              on: {
                'on-change': val => {
                  if (!row.id) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      unicomGuid: val.value,
                      unicomName: val.label
                    });
                  } else {
                    for (var i = 0; i < this.listData.length; i++) {
                      this.listData[i].id === row.id && this.listData.splice(i, 1, {
                        ...this.listData[i],
                        unicomGuid: val.value,
                        unicomName: val.label
                      })
                    }
                  }
                }
              }
            }, this.llOptions.map(el => 
              c('Option', {
                props: {
                  value: el.value,
                }
              }, el.label)
            ))
          }
        },
        {
          title: '通道类型/通道名称',
          key: 'channel',
          minWidth: 140,
          render: (c, {row}) => {
            if (!row.isE) return c('span', row.channelTypeName + ' / ' + row.channelName);
            row.id && (row.channelTypeArr = [+row.channelType, row.guid]);
            return c('Cascader', {
              props: {
                labelInValue: true,
                value: row.channelTypeArr,
                data: this.channelTypeOptions,
                loadData: (item, cb) => {
                  this.getChannelList(item, cb, {
                    id: row.id,
                    type: row.channelType,
                    no: row.channelNo,
                    name: row.channelName,
                    guid: row.guid,
                  })
                }
              },
              'class': {
                noPass: row.channelTypeArr && row.channelTypeArr.length > 1 ? false : true,
              },
              ref: 'channelTypeCas',
              on: {
                'on-change': (val, selData) => {
                  if (!row.id) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      channelTypeArr: val,
                      channelType: val[0],
                      guid: val[1],
                      channelName: selData[1].label,
                      channelNo: +selData[1].num
                    })
                  } else {
                    for (let i = 0; i < this.listData.length; i++) {
                      this.listData[i].id === row.id && this.listData.splice(i, 1, {
                        ...this.listData[i],
                        channelTypeArr: val,
                        channelType: val[0],
                        guid: val[1],
                        channelName: selData[1].label,
                        channelNo: +selData[1].num
                      })
                    }
                  }
                }
              }
            })
          }
        },
        {
          title: '监测值范围',
          key: 'categoryRange',
          minWidth: 160,
          render: (m, {row}) => {
            let c = this.$createElement;
            if (!row.isE) return c('span', row.categoryMinRange + '~' + row.categoryMaxRange);
            let min = c('div', {
              'class': {
                noPass: (row.categoryMinRange || +row.categoryMinRange === 0)
                  && (+row.categoryMinRange <= +row.categoryMaxRange) ? false : true,
              },
            }, [
              c('InputNumber', {
                props: {
                  value: row.categoryMinRange,
                  step: 0.1
                },
                style: {
                  width: '60px'
                },
                ref: 'smcmrInput',
                on: {
                  'on-change': val => {
                    if (!row.id) {
                      this.listData.splice(0, 1, {
                        ...this.listData[0],
                        categoryMinRange: val
                      })
                    } else {
                      for (var i = 0; i < this.listData.length; i++) {
                        this.listData[i].id === row.id && this.listData.splice(i, 1, {
                          ...this.listData[i],
                          categoryMinRange: val
                        })
                      }
                    }
                    this.$nextTick(() => {
                      this.$refs.smcmrInput.$el.children[1].children[0].focus();
                    })
                  }
                }
              })
            ]);
            let max = c('div', {
              'class': {
                noPass: (row.categoryMaxRange || +row.categoryMaxRange === 0)
                  && (+row.categoryMinRange <= +row.categoryMaxRange) ? false : true,
              }
            }, [
              c('InputNumber', {
                props: {
                  value: row.categoryMaxRange,
                  step: 0.1
                },
                style: {
                  width: '60px'
                },
                ref: 'smcmxrInput',
                on: {
                  'on-change': val => {
                    if (!row.id) {
                      this.listData.splice(0, 1, {
                        ...this.listData[0],
                        categoryMaxRange: val
                      })
                    } else {
                      for (var i = 0; i < this.listData.length; i++) {
                        this.listData[i].id === row.id && this.listData.splice(i, 1, {
                          ...this.listData[i],
                          categoryMaxRange: val
                        })
                      }
                    }
                    this.$nextTick(() => {
                      this.$refs.smcmxrInput.$el.children[1].children[0].focus();
                    })
                  }
                }
              })
            ]);
            return c('div', {
              style: {
                display: 'flex',
                alignItems: 'center'
              }
            }, [min, c('span', {style: {margin: '0 5px'}}, '~'), max])
          }
        },
        {
          title: '量程范围',
          key: 'range',
          minWidth: 160,
          render: (m, {row}) => {
            let c = this.$createElement;
            if (!row.isE) {
              let obj = JSON.parse(row.computeJson || '{}');
              return row.channelType === 1 ? c('span', (obj.minRange || '') + ' ~ ' + (obj.maxRange || '')) : c('span', '--');
            }
            if (+row.channelType === 1) {
              let min = c('div', {
                'class': {
                  noPass: (row.minRange || +row.minRange === 0)
                    && (+row.minRange <= +row.maxRange) ? false : true,
                },
              }, [
                c('InputNumber', {
                  props: {
                    value: row.minRange,
                    step: 0.1
                  },
                  style: {
                    width: '60px'
                  },
                  ref: 'smmrInput',
                  on: {
                    'on-change': val => {
                      if (!row.id) {
                        this.listData.splice(0, 1, {
                          ...this.listData[0],
                          minRange: val
                        })
                      } else {
                        for (var i = 0; i < this.listData.length; i++) {
                          this.listData[i].id === row.id && this.listData.splice(i, 1, {
                            ...this.listData[i],
                            minRange: val
                          })
                        }
                      }
                      this.$nextTick(() => {
                        this.$refs.smmrInput.$el.children[1].children[0].focus();
                      })
                    }
                  }
                })
              ]);
              let max = c('div', {
                'class': {
                  noPass: (row.maxRange || +row.maxRange === 0)
                    && (+row.minRange <= +row.maxRange) ? false : true,
                }
              }, [
                c('InputNumber', {
                  props: {
                    value: row.maxRange,
                    step: 0.1
                  },
                  style: {
                    width: '60px'
                  },
                  ref: 'smmxrInput',
                  on: {
                    'on-change': val => {
                      if (!row.id) {
                        this.listData.splice(0, 1, {
                          ...this.listData[0],
                          maxRange: val
                        })
                      } else {
                        for (var i = 0; i < this.listData.length; i++) {
                          this.listData[i].id === row.id && this.listData.splice(i, 1, {
                            ...this.listData[i],
                            maxRange: val
                          })
                        }
                      }
                      this.$nextTick(() => {
                        this.$refs.smmxrInput.$el.children[1].children[0].focus();
                      })
                    }
                  }
                })
              ]);
              return c('div', {
                style: {
                  display: 'flex',
                  alignItems: 'center'
                }
              }, [min, c('span', {style: {margin: '0 5px'}}, '~'), max])
            } else {
              return c('span', '--')
            }
          }
        },
        {
          title: '微安值范围',
          key: 'rangeMicro',
          minWidth: 160,
          render: (m, {row}) => {
            let c = this.$createElement;
            if (!row.isE) {
              let obj = JSON.parse(row.computeJson || '{}');
              return row.channelType === 1 ? c('span', (obj.minRangeMicro || '') + ' ~ ' + (obj.maxRangeMicro || '')) : c('span', '--');
            }
            if (+row.channelType === 1) {
              let min = c('div', {
                'class': {
                  noPass: (row.minRangeMicro || +row.minRangeMicro === 0)
                    && (+row.minRangeMicro <= +row.maxRangeMicro) ? false : true,
                },
              }, [
                c('InputNumber', {
                  props: {
                    value: row.minRangeMicro,
                    step: 0.1
                  },
                  style: {
                    width: '60px'
                  },
                  ref: 'smmrmInput',
                  on: {
                    'on-change': val => {
                      if (!row.id) {
                        this.listData.splice(0, 1, {
                          ...this.listData[0],
                          minRangeMicro: val
                        })
                      } else {
                        for (var i = 0; i < this.listData.length; i++) {
                          this.listData[i].id === row.id && this.listData.splice(i, 1, {
                            ...this.listData[i],
                            minRangeMicro: val
                          })
                        }
                      }
                      this.$nextTick(() => {
                        this.$refs.smmrmInput.$el.children[1].children[0].focus();
                      })
                    }
                  }
                })
              ]);
              let max = c('div', {
                'class': {
                  noPass: (row.maxRangeMicro || +row.maxRangeMicro === 0)
                    && (+row.minRangeMicro <= +row.maxRangeMicro) ? false : true,
                }
              }, [
                c('InputNumber', {
                  props: {
                    value: row.maxRangeMicro,
                    step: 0.1
                  },
                  style: {
                    width: '60px'
                  },
                  ref: 'smmxrmInput',
                  on: {
                    'on-change': val => {
                      if (!row.id) {
                        this.listData.splice(0, 1, {
                          ...this.listData[0],
                          maxRangeMicro: val
                        })
                      } else {
                        for (var i = 0; i < this.listData.length; i++) {
                          this.listData[i].id === row.id && this.listData.splice(i, 1, {
                            ...this.listData[i],
                            maxRangeMicro: val
                          })
                        }
                      }
                      this.$nextTick(() => {
                        this.$refs.smmxrmInput.$el.children[1].children[0].focus();
                      })
                    }
                  }
                })
              ]);
              return c('div', {
                style: {
                  display: 'flex',
                  alignItems: 'center'
                }
              }, [min, c('span', {style: {margin: '0 5px'}}, '~'), max])
            } else {
              return c('span', '--')
            }
          }
        },
        {
          title: '百分比(%)',
          key: 'percentage',
          minWidth: 100,
          render: (m, {row}) => {
            let c = this.$createElement;
            if (!row.isE) {
              let obj = JSON.parse(row.computeJson || '{}');
              return +row.channelType === 4 ? c('span', obj.percentage || '') : c('span', '--');
            }
            if (+row.channelType === 4) {
              return c('div', {
                'class': {
                  noPass: isNaN(+row.percentage) || !row.percentage || +row.percentage === 0 ? true : false,
                },
              }, [
                c('Input', {
                  props: {
                    value: row.percentage,
                    // formatter: value => `${value}%`,
                    /* max: 100,
                    min: 0,
                    step: 1 */
                  },
                  ref: 'smperInput',
                  on: {
                    'on-change': e => {
                      // if (isNaN(Number(val))) return;
                      let val = e.target.value
                      if (!row.id) {
                        this.listData.splice(0, 1, {
                          ...this.listData[0],
                          percentage: val
                        });
                      } else {
                        for (var i = 0; i < this.listData.length; i++) {
                          this.listData[i].id === row.id && this.listData.splice(i, 1, {
                            ...this.listData[i],
                            percentage: val
                          })
                        }
                      }
                      this.$nextTick(() => {
                        this.$refs.smperInput.focus();
                      })
                    }
                  }
                })
              ])
            } else {
              return c('span', '--')
            }
          }
        },
        {
          title: '读取位数',
          key: 'num',
          minWidth: 100,
          render: (m, {row}) => {
            let c = this.$createElement;
            if (!row.isE) {
              let obj = JSON.parse(row.computeJson || '{}');
              return +row.channelType === 4 ? c('span', obj.num || '') : c('span', '--');
            }
            if (+row.channelType === 4) {
              return c('div', {
                'class': {
                  noPass: row.num || +row.num === 0 ? false : true,
                },
              }, [
                c('InputNumber', {
                  props: {
                    value: row.num,
                    // formatter: value => `${value}%`,
                    max: 100,
                    min: 0,
                    step: 1
                  },
                  ref: 'smReadInput',
                  on: {
                    'on-change': val => {
                      if (!row.id) {
                        this.listData.splice(0, 1, {
                          ...this.listData[0],
                          num: val
                        });
                      } else {
                        for (var i = 0; i < this.listData.length; i++) {
                          this.listData[i].id === row.id && this.listData.splice(i, 1, {
                            ...this.listData[i],
                            num: val
                          })
                        }
                      }
                      this.$nextTick(() => {
                        this.$refs.smReadInput.$el.children[1].children[0].focus();
                      })
                    }
                  }
                })
              ])
            } else {
              return c('span', '--')
            }
          }
        },
        {
          title: '备注',
          key: 'remark',
          minWidth: 110,
          render: (c, {row}) => {
            return !row.isE ? 
              c('span', row.remark) :
                c('Input', {
                  attrs: {
                    // value: this.newData.name
                  },
                  props: {
                    value: row.remark,
                  },
                  on: {
                    'on-change': (e) => {
                      row.remark = e.target.value
                    }
                  }
                })
          }
        },
        {
          title: '状态',
          key: 'statusName',
          minWidth: 90,
          render: (c, {row}) => {
            if (!row.isE) return c('span', row.statusName);
            return c('span', '--')
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          minWidth: 110,
          // fixed: 'right',
          render: (c, {row}) => {
            if (row.isE) {
              return c('div', [
                c('Icon', {
                  attrs: {
                    title: '保存'
                  },
                  style: {
                    marginRight: '15px',
                    cursor: 'pointer',
                    color: '#08DDDD',
                    fontSize: '16px',
                  },
                  props: {
                    type: 'md-checkmark'
                  },
                  nativeOn: {
                    click: () => {
                      !row.id ? this.saveMonitorNew(row) : this.saveMonitorEdit(row);
                    }
                  }
                }),
                c('Icon', {
                  attrs: {
                    title: '取消'
                  },
                  style: {
                    cursor: 'pointer',
                    color: '#08DDDD',
                    fontSize: '16px',
                  },
                  props: {
                    type: 'md-close'
                  },
                  nativeOn: {
                    click: () => {
                      !row.id ? this.cancelMonitorNew(row) : this.cancelMonitorEdit(row)
                    }
                  }
                }),
              ])
            } else {
              let delBtn = c('Icon', {
                attrs: {
                  title: '删除'
                },
                style: {
                  cursor: 'pointer',
                  color: '#e4393c',
                  fontSize: '16px',
                },
                props: {
                  type: 'md-trash'
                }
              });
              return this.deleteLoading && this.deletingId === row.id ? c('Spin', {
                props: {
                  size: 'small'
                }
              }) : c('div', [
                this.accessData.sceneSensorMod && c('Icon', {
                  attrs: {
                    title: '编辑'
                  },
                  style: {
                    cursor: 'pointer',
                    color: '#08DDDD',
                    fontSize: '16px',
                    marginRight: '10px'
                  },
                  props: {
                    type: 'ios-brush'
                  },
                  nativeOn: {
                    click: () => {
                      this.monitorEdit(row)
                    }
                  }
                }),
                c('Icon', {
                  attrs: {
                    title: '设置自定义数值范围'
                  },
                  style: {
                    cursor: 'pointer',
                    color: '#00b477',
                    fontSize: '16px',
                    marginRight: '10px'
                  },
                  props: {
                    type: 'ios-pulse'
                  },
                  nativeOn: {
                    click: () => {
                      this.monitorRange(row)
                    }
                  }
                }),
                this.accessData.sceneSensorDel && c('Poptip', {
                  props: {
                    confirm: true,
                    title: '确认删除？',
                    placement: 'left'
                  },
                  on: {
                    'on-ok': () => {
                      this.monitorDel(row.id)
                    },
                    'on-cancel': () => {

                    }
                  }
                }, [delBtn])
              ])
            }
          }
        }
      ],
      listData: [],
      llOptions: [],
      page: {
        total: 0,
        pageNo: 1,
        totalPage: 0,
        pageSize: 10
      },
      editData: {},
      sensorTypeOptions: [],
      sensorCategoryOptions: [],
      channelTypeOptions: [],
      listLoading: false,
      deleteLoading: false,
      deletingId: '',
      filter: {},
      farmScene: [],
      farmOptions: [],
    }
  },
  computed: {
    accessData () {
      return {
        // 检测、控制、视频表格权限
        sceneSensorAdd: true, // this.$store.state.app.permissionsGroup.sceneSensorAdd,
        sceneSensorMod: true, // this.$store.state.app.permissionsGroup.sceneSensorMod,
        sceneSensorDel: true, // this.$store.state.app.permissionsGroup.sceneSensorDel,
      }
    }
  },
  methods: {
    getFarmList () {
      return this.$post(this.$api.FARM.LIST, {
        pageNo: 1,
        pageSize: 99999
      }).then(resp => {
        return this.farmOptions = resp.list.map(item => ({
          value: +item.farmId,
          label: item.name,
          children: [],
          loading: false,
        }));
      })
    },
    farmSceneChange (val, selData) {
      this.filter = {
        ...this.filter,
        farmId: val[0],
        sceneId: val[1]
      };
      this.getList(true)
    },
    getChildren (item, cb) {
      item.loading = true,
      this.$post(this.$api.FARM.SCENE_LIST, {
        farmId: item.value,
        pageSize: 99999,
        pageNo: 1,
      }).then(resp => {
        item.children = resp.list.map(elem => ({
          value: elem.sceneId,
          label: elem.name,
        }));
        item.loading = false;
        cb();
      }).catch(e => {
        item.loading = false;
        cb();
      })
    },

    pageChange (pageNo) {
      this.page.pageNo = pageNo
      this.getList(true)
    },

    filterChange (field, type, val, selData) {
      if (type === 'cas') {
        if (val.length === 1) {
          this.filter = {
            ...this.filter,
            sensorType: val[0],
          }
        } else if (val.length === 2) {
          this.filter = {
            ...this.filter,
            sensorType: val[0],
            sensorCategory: val[1],
          }
        } else {
          this.filter = {
            ...this.filter,
            sensorType: void 0,
            sensorCategory: void 0,
          }
        }
        this.getList(true);
      } else if (type === 'input') {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.filter = {
            ...this.filter,
            [field]: val.target.value ? val.target.value : void 0,
          };
          this.getList(true)
        }, 500);
      }
    },

    getList(loadingShow) {
      loadingShow && (this.listLoading = true);
      this.$post(this.$api.MONITOR.MONITOR_LIST, {
        pageSize: this.page.pageSize,
        pageNo: this.page.pageNo,
        ...this.filter,
      }).then(resp => {
        this.listData = resp.list.map(item => {
          return {
            ...item,
            farmId: +item.farmId,
            ...JSON.parse(item.computeJson || '{}'),
          }
        });
        this.page = {
          total: +resp.total,
          pageNo: +resp.pageNo,
          totalPage: +resp.totalPage,
          pageSize: +resp.pageSize,
        }
        loadingShow && (this.listLoading = false);
      }).catch(e => {
        loadingShow && (this.listLoading = false);
      })
    },

    // 获取场景类型（传感器类型）
    getSensorTypeList () {
      this.$post(this.$api.COMMON.DICT, {
        name: '场景类型'
      }).then(resp => {
        var sensorTypeOptions = []
        for (var val of resp) {
          sensorTypeOptions.push({
            value: val.num,
            label: val.name,
            children: [],
            loading: false,
          })
        }
        this.sensorTypeOptions = sensorTypeOptions
      })
    },
    
    // 获取监测类别
    getSensorCategoryList (item, cb) {
      item.loading = true;
      var name = ''
      switch (item.value) {
        case 1:
          name = '种植业传感器分类'
          break
        case 2:
          name = '养殖业传感器分类'
          break
        case 3:
          name = '水产业传感器分类'
          break;
      }
      this.$post(this.$api.COMMON.DICT, {
        name
      }).then(resp => {
        var sensorCategoryOptions = [];
        for (var val of resp) {
          sensorCategoryOptions.push({
            value: val.num,
            label: val.name
          })
        };
        item.children = sensorCategoryOptions;
        item.loading = false;
        cb();
      }).catch(e => {
        item.loading = false;
        cb();
      })
    },

    getLlOptions () {
      this.$post(this.$api.DEVICES.LUOLA_LIST, {
        pageSize: 9999,
        pageNo: 1,
      }).then(resp => {
        if (!resp.list) return;
        this.llOptions = resp.list.map(el => ({
          label: el.name,
          value: el.guid,
        }))
      })
    },

    monitorNew () {
      if (!this.filter.farmId || !this.filter.sceneId) {
        this.$Modal.info({
          title: '提示',
          content: '添加监测配置前，请先选择所属基地场景！',
          okText: '我知道了！'
        })
        return
      }
      if (this.listData[0] && this.listData[0].isE) return;
      this.listData.unshift({
        isE: true,
        categoryMinRange: 0,
        categoryMaxRange: 0,
      })
    },

    saveMonitorNew (record) {
      let noPass = !record.groupName
      || !record.name
      || !record.sensorType
      || !record.sensorCategory
      || !record.channelType
      || !record.unicomGuid
      || ((!record.channelNo && record.channelNo !== 0) || !record.channelName || !record.guid)
      || !(record.channelType)
      || !(record.categoryMaxRange || +record.categoryMaxRange === 0)
      || !(record.categoryMinRange || +record.categoryMinRange === 0)
      || !(+record.categoryMinRange <= +record.categoryMaxRange)
      || !(record.channelType === 1 
        && (record.maxRange || +record.maxRange === 0)
        && (record.minRange || +record.minRange === 0)
        && (+record.maxRange >= +record.minRange)
        && (record.maxRangeMicro || +record.maxRangeMicro === 0)
        && (record.minRangeMicro || +record.minRangeMicro === 0)
        && (+record.maxRangeMicro >= +record.minRangeMicro)
        || record.channelType === 4
        && (record.percentage && +record.percentage !== 0)
        && (record.num || +record.num === 0)
        || (+record.channelType === 0)
        );
      if (noPass) return;
      this.listLoading = true;
      delete record.isE;
      this.$post(this.$api.MONITOR.MONITOR_NEW2, {
        ...record,
        farmId: this.filter.farmId,
        sceneId: this.filter.sceneId,
      }).then(resp => {
        this.getList(true)
      }).catch(e => {
        this.listLoading = false;
      })
    },

    cancelMonitorNew () {
      this.listData.shift()
    }, 

    monitorEdit (record) {
      this.channelTypeOptions = this.channelTypeOptions.map(item => {
        if (item.value === +record.channelType) {
          return {
            ...item,
            children: []
          }
        } else {
          return item
        }
      })
      let listData = this.listData.map(item => {
        return item.id === record.id ? {...item, isE: true} : item
      })
      this.listData = listData;
      this.editData = {...record}
    },

    saveMonitorEdit (record) {
      let noPass = !record.groupName
      || !record.name
      || !record.sensorType
      || !record.sensorCategory
      || !(record.channelType)
      || !record.unicomGuid
      || ((!record.channelNo && record.channelNo !== 0) || !record.channelName || !record.guid)
      || !(record.channelType)
      || !(record.categoryMaxRange || +record.categoryMaxRange === 0)
      || !(record.categoryMinRange || +record.categoryMinRange === 0)
      || !(+record.categoryMinRange <= +record.categoryMaxRange)
      || !(record.channelType === 1 
        && (record.maxRange || +record.maxRange === 0)
        && (record.minRange || +record.minRange === 0)
        && (+record.maxRange >= +record.minRange)
        && (record.maxRangeMicro || +record.maxRangeMicro === 0)
        && (record.minRangeMicro || +record.minRangeMicro === 0)
        && (+record.maxRangeMicro >= +record.minRangeMicro)
        || record.channelType === 4
        && (record.percentage && +record.percentage !== 0)
        && (record.num || +record.num === 0)
        || (+record.channelType === 0)
        )
        if (noPass) return;
        this.listLoading = true;
        delete record.isE;
        this.$post(this.$api.MONITOR.MONITOR_EDIT2, {
          ...record,
          farmId: this.filter.farmId,
          sceneId: this.filter.sceneId,
          id: record.id
        }).then(resp => {
          this.getList(true)
        }).catch(e => {
          this.listLoading = false;
        })
    },

    cancelMonitorEdit (record) {
      this.listData = this.listData.map(item => {
        return item.id === record.id ? this.editData : item
      });
    },

    monitorRange (data) {
      let na = data.name;
      let gu = data.guid;
      this.$router.push({
        path: `moniRange?n=${na}&g=${gu}`
      })
    },

    monitorDel (id) {
      this.deletingId = id;
      this.deleteLoading = true;
      this.$post(this.$api.MONITOR.MONITOR_DEL, {
        id
      }).then(resp => {
        this.deleteLoading = false;
        this.getList(true)
      }).catch(e => {
        this.deleteLoading = false;
      })

    },

    getChannelType () {
      this.$post(this.$api.COMMON.DICT, {
        name: '设备厂商'
      }).then(resp => {
        this.channelTypeOptions = resp.map(item => {
          return {
            value: item.num,
            label: item.name,
            children: [],
            loading: false,
          }
        })
      })
    },

    getChannelList (item, cb, {id, type, no, name, guid}) {
      // 后台并未返回已绑定的通道，所以编辑填充表单时需要手动unshift列表中存在的通道数据
      item.loading = true;
      let api = '';
      let params = {
        sceneId: this.sceneId,
        isNotStatus: 1,
      };
      switch (item.value) {
        case 1:
          api = this.$api.AISLE.JY_AISLELIST;
          params = {
            ...params,
            doType: 0,
            pageNo: 1,
            pageSize: 99999,
          };
          break;
        case 4:
          api = this.$api.AISLE.SBW_AISLELIST;
          params = {
            ...params,
            pageNo: 1,
            pageSize: 99999,
          }
      }
      this.$post(api, {
        ...params
      }).then(resp => {
        if (item.value === 1) {
          item.children = resp.list.map(item => {
            return {
              value: item.guid,
              label: item.channelName,
              num: item.channelNo,
            }
          });
          id && item.children.unshift({
            value: guid,
            label: name,
            num: +no,
          });
        } else if (item.value === 4) {
          item.children = resp.list.map(item => {
            return {
              value: item.guid,
              label: item.nodeName,
              num: item.nodeAddress,
            }
          });
          id && item.children.unshift({
            value: guid,
            label: name,
            num: +no,
          });
        }
        
        item.loading = false,
        cb();
      }).catch(e => {
        item.loading = false;
        cb();
      })
    },

    getSceneList () {
      return this.$post(this.$api.FARM.SCENE_LIST, {
        farmId: this.farmCurrentId,
        pageSize: 99999,
        pageNo: 1,
      }).then(resp => {
        return resp.list.map(el => el)
      }).catch(e => {
        
      })
    },
  },
  mounted () {
    this.getFarmList().then(resp => {
      if (!this.farmId) {
        this.filter.farmId = resp[0] ? resp[0].value : void 0;
        this.getSceneList().then(res => {
          if (!this.sceneId) {
            this.filter.sceneId = res[0] ? res[0].sceneId : void 0;
          }
          this.farmScene = [this.filter.farmId, this.filter.sceneId];
          this.getList(true);
        })
      } else {
        this.filter = {
          farmId: this.farmId,
          sceneId: this.sceneId,
        }
        this.farmScene = [this.filter.farmId, this.filter.sceneId];
        this.getList(true);
      }
    })
    this.getSensorTypeList();
    this.getChannelType()
    this.getLlOptions()
  },
}
</script>
<style lang="less">
.smonitor {
  .filterBar {
    margin-bottom: 10px;
    .filterItem {
      margin-right: 10px;
    }
  }
  .monitorGrid {
    // margin-top: 15px;
    > div {
      overflow: visible;
    }
  }
  .pageBar {
    margin-top: 10px;
    text-align: right;
  }
  .noPass {
    input {
      border: 1px solid #e4393c;
    }
    .ivu-select-selection {
      border: 1px solid #e4393c;
    }
    .ivu-input-number {
      border: 1px solid #e4393c;
      input {
        border: none;
      }
    }
  }
}
</style>


